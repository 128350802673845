import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'
import { StaticImage } from 'gatsby-plugin-image'
import s1 from '../images/pragicts-software-development-process.png'
const WebsiteDeliveryScope = () => {
    return (
        <Layout pageTitle="Why have an Online Presence (Website)?" og_desc="">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>WHY HAVE AN ONLINE<br/>
<span> PRESENCE (WEBSITE)?</span></h2>
                                <p></p>
                            
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
                    <section data-scrollax-parent="true" id="sec1">
                        <div className="section-subtitle left-pos"  data-scrollax="properties: { translateY: '-250px' }" ><span></span></div>
                        <div className="container main-about">
                          


                            
                        
                            
                           <p>< h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>Let the numbers do the talk</h3> <h6 style={{fontSize: '13px'}}>8.10 billion global population<br/>5.35 billion (66.2%) Internet penetration<br/>6.7 billion (82.7%) smartphone penetration<br/>4.97 billion (61.4%) use social media<br/>2.02 billion (25%)</h6>
                          </p>
                          <p>< h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>eCommerce</h3> <h6 style={{fontSize: '13px'}}>The global e-commerce penetration rate is expected to hit 25% (2.02 billion) by 2027<br/>The global e-commerce market will be valued at $ 18.98 Trillion in 2022. Its market size is projected to reach $ 47.73 trillion by 2030</h6>
                          </p>
                          <p>< h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>Use of Plastics</h3> <h6 style={{fontSize: '13px'}}>Global card purchase volume hits $42.7 trillion.</h6>
                          </p>
                          <p>< h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>Online Conveniences</h3> <h6 style={{fontSize: '13px'}}>Uber, the world’s largest taxi company, owns no vehicles.<br/>Alibaba, the most valuable retailer, has no inventory. <br/>Airbnb, the world’s largest accommodation provider, owns no real estate.<br/>Facebook, the world’s most popular media owner, creates no content.</h6>
                          </p>

                          <p>< h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>Today's Defacto Purchasing Process</h3> <h6 style={{fontSize: '13px'}}>Five of the sever activities are done online and the final decision is guided and influenced as follows<br/>01 Search Online<br/>02 View Online<br/>03 Compare Online<br/>04 Learn Online<br/>05 Make a decision based on your online research<br/>06 Call<br/>07 Purchase</h6>
                          </p>
<div>










</div> 





















                        </div>
                     
                        <div className="bg-parallax-module" data-position-top="50"  data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
                        <div className="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
                        <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
                        <div className="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
                        <div className="sec-lines"></div>
                    </section>
                        
                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default WebsiteDeliveryScope
